import { getAppSettings } from './config';

export const ROUTES = {
    SIGN_IN_ERROR: '/signinerror',
    SIGN_IN: '/signin',
    LOGGED_OUT: '/loggedout',
    APPT_DETAILS: '/appt-details',
    APPT_SELF_RESCHEDULE: '/appt-self-reschedule',
    APPT_SELF_SCHEDULE: '/appt-self-schedule',
    AUTHENTICATED: '/authenticated',
    SURVEY: '/survey',
    QUESTIONNAIRES: '/questionnaires',
    SURVEY_COMPLETED: '/surveycompleted',
    NOTIFICATION_PREFERENCES: '/preferences',
    UNSUBSCRIBE_SAT_SURVEY: '/unsubscribe/sat-survey',
    UNSUBSCRIBE_APPT_CONFIRMATION: '/unsubscribe/appt-confirmation',
    UNSUBSCRIBE_RESOURCES: '/unsubscribe/resources',
    RESOURCES: '/resources',
    CONVERSATION: '/conversation',
    MESSAGES: '/messages',
    EMAIL_UNSUBSCRIBE: '/email-unsubscribe',
};

export const SUPPORT_INFO = {
    DOXY_VIDEO: 'https://help.doxy.me/en/articles/3751218-for-patients-how-to-check-in',
};

export const ROUTE_PARAMS = {
    PATIENT_ID: 'patientId',
    MAGIC_LINK_ID: 'authToken',
    CALENDAR_EVENT_ID: 'eventId',
    DOWNLOAD_CALENDAR: 'downloadCalendar',
    EPISODE_ID: 'episodeId',
    CONVERSATION_ID: 'conversationId',
    UNSUBSCRIBE_OPTION: 'unsubscribeOption',
    APPOINTMENT_DURATION: 'duration',
    APPOINTMENT_TYPE: 'type',
};

const { supportPhoneNumber } = getAppSettings();

export const GENERIC_ERROR_MESSAGE = 'something went wrong while trying to fetch your request.';
export const ADD_TO_CALENDAR_NOT_AVAILABLE_MESSAGE = 'Add to calendar is not available for this appointment.';
export const UNABLE_TO_LOAD_QUESTIONNAIRES =
    'Sorry, questionnaires are unavailable at this time. Please return to the appointment and try again later.';
export const SURVEY_COMPLETED_SUCCESS_MESSAGE = 'Answers successfully submitted.';
export const UNABLE_TO_SAVE_SURVEY_WITH_UNANSWERED_QUESTIONS = 'Please answer required questions';
export const RESCHEDULE_APPOINTMENT_WARNING =
    'To help ensure availability for all, please keep your appointment or make changes at least 2 hours beforehand. Thank you!';
export const UNABLE_TO_LOAD_RESOURCES = 'Sorry, resources are unavailable at this time. Please try again later.';
export const UNABLE_TO_DOWNLOAD_RESOURCE = 'Sorry, the resource is unavailable at this time. Please try again later.';
export const RE_ENGAGING_MESSAGE = `To re-connect with Concert services, call us at ${supportPhoneNumber}.`;
export const MESSAGE_SUCCESSFULLY_SUBMITTED = `Message sent. Please allow 1-2 business days for a response from your Collaborative Care Clinician.`;
