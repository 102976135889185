import { FunctionComponent, useRef, Suspense, lazy } from 'react';
import 'primereact/resources/themes/saga-orange/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'concert-ui-library/dist/styles/components/loader/loader.scss';
import './App.scss';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { NotificationFunctions, Notification as AppNotification, Loader } from 'concert-ui-library';
import { Provider } from 'react-redux';
import Rum from './containers/rum/rum';
import Nav from './containers/nav/nav';
import { NotificationContext } from './notification-context';
import { ProtectedRoute } from './protected-route';
import { ROUTES } from './constants';
import initStore from './store';
import NotificationProvider from './notification-provider';
import { GlobalNotificationComponent } from './containers/notification-container/notification-container';
import { MaintenanceObservingRoute } from './maintenance-observing-route';
import { ConversationCenter } from './containers/secure-messages/conversation-center';

const store = initStore();
type AppProps = {
    history: RouteComponentProps['history'];
};

const SignInError = lazy(() => import('./containers/sign-in/sign-in-error'));
const SignIn = lazy(() =>
    import('./containers/notification-preferences/slice').then(async (module) => {
        store.injectReducer('patientContext', module.reducer);
        const saga = await import('./containers/notification-preferences/saga');
        store.injectSaga('patientContext', saga.watchPatientContext);
        return import('./containers/sign-in/sign-in');
    }),
);
const SurveyThankYou = lazy(() => import('./containers/survey-thank-you/survey-thank-you'));
const SurveyUnsubscribe = lazy(() =>
    import('./containers/notification-preferences/slice').then(async (module) => {
        store.injectReducer('patientContext', module.reducer);
        const saga = await import('./containers/notification-preferences/saga');
        store.injectSaga('patientContext', saga.watchPatientContext);
        return import('./containers/communication-unsubscribe/survey-unsubscribe');
    }),
);

const ResourcesUnsubscribe = lazy(() =>
    import('./containers/notification-preferences/slice').then(async (module) => {
        store.injectReducer('patientContext', module.reducer);
        const saga = await import('./containers/notification-preferences/saga');
        store.injectSaga('patientContext', saga.watchPatientContext);
        return import('./containers/resources/resources-unsubscribe');
    }),
);

const ApptConfirmationUnsubscribe = lazy(() =>
    import('./containers/notification-preferences/slice').then(async (module) => {
        store.injectReducer('patientContext', module.reducer);
        const saga = await import('./containers/notification-preferences/saga');
        store.injectSaga('patientContext', saga.watchPatientContext);
        return import('./containers/appointments/appt-confirmation/appt-confirmation-unsubscribe');
    }),
);

const EmailUnsubscribe = lazy(() =>
    import('./containers/notification-preferences/slice').then(async (module) => {
        store.injectReducer('patientContext', module.reducer);
        const saga = await import('./containers/notification-preferences/saga');
        store.injectSaga('patientContext', saga.watchPatientContext);
        return import('./containers/communication-unsubscribe/email-unsubscribe');
    }),
);
const NotificationPreferences = lazy(() =>
    import('./containers/notification-preferences/slice').then(async (module) => {
        store.injectReducer('patientContext', module.reducer);
        const saga = await import('./containers/notification-preferences/saga');
        store.injectSaga('patientContext', saga.watchPatientContext);
        return import('./containers/notification-preferences/notification-preferences');
    }),
);
const Authenticated = lazy(() => import('./containers/sign-in/authenticated'));

const Resources = lazy(() =>
    import('./containers/notification-preferences/slice').then(async (module) => {
        store.injectReducer('patientContext', module.reducer);
        const saga = await import('./containers/notification-preferences/saga');
        store.injectSaga('patientContext', saga.watchPatientContext);
        return import('./containers/resources/resources');
    }),
);
const AppointmentDetails = lazy(() =>
    import('./services/appt-details-api').then((module) => {
        store.injectReducer(module.apptDetailsApi.reducerPath, module.apptDetailsApi.reducer);
        return import('./containers/appointments/appt-details/appt-details');
    }),
);

const AppointmentSelfReschedule = lazy(async () => {
    const apptDetailsApi = import('./services/appt-details-api').then((module) => {
        store.injectReducer(module.apptDetailsApi.reducerPath, module.apptDetailsApi.reducer);
    });
    const rescheduleRequestApi = import('./containers/appointments/appt-details/slice').then((module) => {
        store.injectReducer('rescheduleRequest', module.reducer);
    });
    await Promise.allSettled([apptDetailsApi, rescheduleRequestApi]);

    return import('./containers/appointments/appt-self-reschedule/appt-self-reschedule.component');
});

const AppointmentSelfSchedule = lazy(async () => {
    return import('./containers/appointments/appt-self-schedule/appt-self-schedule.component');
});

const TemplatedSurvey = lazy(() =>
    import('./containers/survey/slice').then(async (module) => {
        store.injectReducer('survey', module.reducer);
        const saga = await import('./containers/survey/saga');
        store.injectSaga('survey', saga.watchSurveys);
        return import('./containers/survey/templated-survey/templated-survey');
    }),
);
const EpisodeQuestionaires = lazy(() =>
    import('./services/survey-template-api').then(async (module) => {
        store.injectReducer(module.surveyTemplateApi.reducerPath, module.surveyTemplateApi.reducer);
        return import('./containers/questionnaires/episode-questionnaires');
    }),
);
const MessageCenter = lazy(() =>
    import('./containers/notification-preferences/slice').then(async (module) => {
        store.injectReducer('patientContext', module.reducer);
        const saga = await import('./containers/notification-preferences/saga');
        store.injectSaga('patientContext', saga.watchPatientContext);
        return import('./containers/secure-messages/message-center');
    }),
);

const LoggedOut = lazy(() => import('./containers/sign-in/logged-out'));
const App: FunctionComponent<AppProps> = ({ history }) => {
    const messages = useRef(null);
    const notificationFunctions = NotificationFunctions(messages);
    return (
        <NotificationProvider>
            <NotificationContext.Provider value={notificationFunctions}>
                <ConnectedRouter history={history}>
                    <Provider store={store}>
                        <Nav />
                        <Rum />
                        <AppNotification messages={messages} />
                        <GlobalNotificationComponent />

                        <Switch>
                            <Suspense fallback={<Loader />}>
                                <Route exact path={ROUTES.SIGN_IN_ERROR} component={SignInError} />
                                <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
                                <Route exact path={ROUTES.LOGGED_OUT} component={LoggedOut} />
                                <ProtectedRoute exact path={ROUTES.AUTHENTICATED} component={Authenticated} />
                                <ProtectedRoute exact path={ROUTES.RESOURCES} component={Resources} />
                                <MaintenanceObservingRoute exact path={ROUTES.SURVEY} component={TemplatedSurvey} />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.QUESTIONNAIRES}
                                    component={EpisodeQuestionaires}
                                />
                                <Route exact path={ROUTES.SURVEY_COMPLETED} component={SurveyThankYou} />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.NOTIFICATION_PREFERENCES}
                                    component={NotificationPreferences}
                                    protected
                                />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.APPT_SELF_RESCHEDULE}
                                    component={AppointmentSelfReschedule}
                                    protected
                                />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.APPT_SELF_SCHEDULE}
                                    component={AppointmentSelfSchedule}
                                />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.UNSUBSCRIBE_SAT_SURVEY}
                                    component={SurveyUnsubscribe}
                                />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.UNSUBSCRIBE_RESOURCES}
                                    component={ResourcesUnsubscribe}
                                />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.EMAIL_UNSUBSCRIBE}
                                    component={EmailUnsubscribe}
                                />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.APPT_DETAILS}
                                    component={AppointmentDetails}
                                />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.UNSUBSCRIBE_APPT_CONFIRMATION}
                                    component={ApptConfirmationUnsubscribe}
                                />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.MESSAGES}
                                    component={MessageCenter}
                                    protected
                                />
                                <MaintenanceObservingRoute
                                    exact
                                    path={ROUTES.CONVERSATION}
                                    component={ConversationCenter}
                                    protected
                                />
                            </Suspense>
                        </Switch>
                    </Provider>
                </ConnectedRouter>
            </NotificationContext.Provider>
        </NotificationProvider>
    );
};

export default App;
